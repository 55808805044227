import { takeLatest, put, call } from "redux-saga/effects";
import {
	fetchSubscriptionStart,
	fetchSubscriptionComplete,
	fetchSubscriptionFail,
	fetchSubscriptionListStart,
	fetchSubscriptionListComplete,
	fetchSubscriptionListFail,
	addMembershipPlanStart,
	addMembershipPlanSuccess,
	addMembershipPlanFail,
	fetchEmailDuplicatedStart,
	fetchEmailDuplicatedComplete,
	fetchEmailDuplicatedFail,
	fetchPlansStart,
	fetchPlansComplete,
	fetchPlansFail,
	fetchPricingFeesFail,
	addTokenToAccountStart,
	addTokenToAccountComplete,
	addTokenToAccountFailure,
	canDeleteTokenCheckStart,
	canDeleteTokenCheckSuccess,
	canDeleteTokenCheckFailure,
	removeTokenToAccountStart,
	removeTokenToAccountComplete,
	removeTokenToAccountFailure,
	saveSubscriptionStart,
	saveSubscriptionFail,
	saveSubscriptionComplete,
	resumeSubscriptionStart,
	resumeSubscriptionComplete,
	resumeSubscriptionFail,
	removeSubscriptionStart,
	removeSubscriptionComplete,
	removeSubscriptionFail,
	cancelSubscriptionStart,
	cancelSubscriptionComplete,
	cancelSubscriptionFail,
	cancelImmediatelyStart,
	cancelImmediatelyComplete,
	cancelImmediatelyFail,
	fetchPricingFeesStart,
	fetchPricingFeesComplete,
	paySubscriptionStart,
	paySubscriptionFail,
	fetchBillingHistoryStart,
	fetchBillingHistoryComplete,
	fetchBillingHistoryFail,
	fetchOrderAccountStart,
	fetchOrderAccountComplete,
	fetchOrderAccountFail,
	fetchReceiptHistoryContentHtmlStart,
	fetchReceiptHistoryContentHtmlSuccess,
	fetchReceiptHistoryContentHtmlFail,
	sendReceiptEmailStart,
	sendReceiptEmailSuccess,
	sendReceiptEmailFail,
	fetchTransientOwnerAccountComplete,
	fetchTransientOwnerAccountFail,
	fetchTransientOwnerAccountStart,
	fetchTransientInfoStart,
	fetchTransientInfoComplete,
} from "./customerMembership.slice";
import CustomerMembershipAPI from "./customerMembership.api";
import CustomerAPI from "../customer/customer.api";

export function* fetchPlans({ payload }) {
	try {
		const { data } = yield call(CustomerMembershipAPI.fetchAvailablePlans);

		yield put(fetchPlansComplete(data));
	} catch (e: any) {
		yield put(fetchPlansFail(e.message));
	}
}

export function* fetchCustomerMembership({ payload }) {
	try {
		const { accountId, subscriptionId } = payload;
		const { data } = yield call(
			CustomerMembershipAPI.fetchCustomerMembership,
			accountId,
			subscriptionId
		);

		yield put(fetchSubscriptionComplete(data));
	} catch (e: any) {
		yield put(fetchSubscriptionFail(e.message));
	}
}

export function* fetchCustomerMemberships({ payload }) {
	try {
		const { data } = yield call(
			CustomerMembershipAPI.fetchCustomerMemberships,
			payload
		);

		yield put(fetchSubscriptionListComplete(data));
	} catch (e: any) {
		yield put(fetchSubscriptionListFail(e.message));
	}
}

export function* checkEmailDuplicated({ payload }) {
	try {
		const { email, accountId } = payload;
		const replacedEmail = email.replace("+", "%2B");
		const { data } = yield call(
			CustomerAPI.getDuplicatedEmailCount,
			replacedEmail,
			accountId
		);

		yield put(fetchEmailDuplicatedComplete(data));
	} catch (error: any) {
		yield put(fetchEmailDuplicatedFail(error.message));
	}
}

export function* addTokenToAccount({ payload }) {
	try {
		const { data } = yield call(CustomerMembershipAPI.addTokenToAccount, payload);
		yield put(addTokenToAccountComplete(data));
	} catch (e: any) {
		yield put(addTokenToAccountFailure(e.message));
	}
}

export function* removeTokenToAccount({ payload }) {
	try {
		const { data } = yield call(CustomerMembershipAPI.removeTokenToAccount, payload);
		yield put(removeTokenToAccountComplete(data));
	} catch (e: any) {
		yield put(removeTokenToAccountFailure(e.message));
	}
}

export function* removeSubscription({ payload }) {
	try {
		const { accountId, subscriptionId } = payload;

		const { data } = yield call(
			CustomerMembershipAPI.removeSubscription,
			accountId,
			subscriptionId
		);
		yield put(removeSubscriptionComplete(data));
	} catch (e: any) {
		yield put(removeSubscriptionFail(e.message));
	}
}

export function* cancelMembershipPlan({ payload }) {
	try {
		const { accountId, subscriptionId } = payload;

		const { data } = yield call(
			CustomerMembershipAPI.cancelMembershipPlan,
			accountId,
			subscriptionId
		);
		yield put(cancelSubscriptionComplete(data));
	} catch (e: any) {
		yield put(cancelSubscriptionFail(e.message));
	}
}

export function* cancelImmediately({ payload }) {
	try {
		const { accountId, subscriptionId } = payload;

		const { data } = yield call(
			CustomerMembershipAPI.cancelSubscriptionImmediately,
			accountId,
			subscriptionId
		);
		yield put(cancelImmediatelyComplete(data));
	} catch (e: any) {
		yield put(cancelImmediatelyFail(e.message));
	}
}

export function* canDeleteTokenCheck({ payload }) {
	try {
		yield call(CustomerMembershipAPI.canDeleteAccountToken, payload);
		yield put(canDeleteTokenCheckSuccess());
	} catch (e: any) {
		yield put(canDeleteTokenCheckFailure(e.message));
	}
}

// TODO: Refactor this later on
// Add membership Plan to Customer
export function* addMembershipPlan({ payload }) {
	try {
		const { accountId, planId } = payload;

		const { data } = yield call(
			CustomerMembershipAPI.addMembershipPlanToCustomer,
			accountId,
			planId
		);

		yield put(addMembershipPlanSuccess(data));
	} catch (e: any) {
		yield put(addMembershipPlanFail(e.message));
	}
}

export function* calculatePricing({ payload }) {
	try {
		const { data } = yield call(CustomerMembershipAPI.calculatePricing, payload);
		yield put(fetchPricingFeesComplete(data));
	} catch (e: any) {
		yield put(fetchPricingFeesFail(e.message));
	}
}

export function* saveSubscription({ payload }) {
	try {
		const { saveModel } = payload;
		const { data } = yield call(CustomerMembershipAPI.saveMembership, saveModel);
		yield put(saveSubscriptionComplete(data));
	} catch (e: any) {
		yield put(saveSubscriptionFail(e.message));
	}
}

export function* resumeSubscription({ payload }) {
	try {
		const { accountId, subscriptionId } = payload;

		const { data } = yield call(
			CustomerMembershipAPI.resumeSubscription,
			accountId,
			subscriptionId
		);
		yield put(resumeSubscriptionComplete(data));
	} catch (e: any) {
		yield put(resumeSubscriptionFail(e.message));
	}
}

export function* paySubscription({ payload }) {
	try {
		const { model } = payload;
		const { data } = yield call(CustomerMembershipAPI.paySubscription, model);

		if (data.message) {
			yield put(paySubscriptionFail(data.message));
		} else {
			yield put(saveSubscriptionStart({ saveModel: model }));
		}
	} catch (e: any) {
		yield put(paySubscriptionFail(e.message));
	}
}

export function* fetchBillingHistory({ payload }) {
	try {
		const { accountId, subscriptionId, pageSize, pageNumber } = payload;
		const { data } = yield call(
			CustomerMembershipAPI.fetchBillingHistory,
			accountId,
			subscriptionId,
			pageSize,
			pageNumber
		);
		yield put(fetchBillingHistoryComplete(data));
	} catch (e: any) {
		yield put(fetchBillingHistoryFail(e.message));
	}
}

export function* fetchOrderAccount({ payload }) {
	try {
		const { orderId } = payload;
		const { data } = yield call(CustomerMembershipAPI.fetchOrderAccount, orderId);

		yield put(fetchOrderAccountComplete(data));
	} catch (e: any) {
		yield put(fetchOrderAccountFail(e.message));
	}
}

export function* fetchReceiptHistoryConentHtml({ payload }) {
	try {
		const { accountId, subscriptionId, subscriptionPaymentHistoryId } = payload;
		const { data } = yield call(
			CustomerMembershipAPI.fetchMailHistory,
			accountId,
			subscriptionId,
			subscriptionPaymentHistoryId
		);

		yield put(fetchReceiptHistoryContentHtmlSuccess(data));
	} catch (e: any) {
		yield put(fetchReceiptHistoryContentHtmlFail(e.message));
	}
}

export function* sendReceiptEmail({ payload }) {
	try {
		const { accountId, subscriptionId, subscriptionPaymentHistoryId, mailTo } =
			payload;
		const { data } = yield call(
			CustomerMembershipAPI.sendReceiptEmail,
			accountId,
			subscriptionId,
			subscriptionPaymentHistoryId,
			mailTo
		);
		yield put(sendReceiptEmailSuccess(data));
	} catch (e: any) {
		yield put(sendReceiptEmailFail(e.message));
	}
}

export function* fetchTransientAccountOwnerTokens({ payload }) {
	try {
		const { data } = yield call(CustomerMembershipAPI.getAccountTokens, payload);
		yield put(
			fetchTransientOwnerAccountComplete({ newAccountOwnerId: payload, data })
		);
	} catch (e: any) {
		yield put(fetchTransientOwnerAccountFail(e.message));
	}
}

export function* fetchTransientInfo({ payload }) {
	const { accountId, subscriptionId } = payload;
	try {
		const { data } = yield call(
			CustomerMembershipAPI.fetchTransientInfo,
			accountId,
			subscriptionId
		);
		yield put(fetchTransientInfoComplete(data));
	} catch (e: any) {
		// yield put(fetchTransientOwnerAccountFail(e.message));
	}
}

// Root Sagas
export function* watchCustomerMembership() {
	return [
		yield takeLatest(fetchPlansStart, fetchPlans),
		yield takeLatest(fetchSubscriptionStart, fetchCustomerMembership),
		yield takeLatest(fetchSubscriptionListStart, fetchCustomerMemberships),
		yield takeLatest(fetchEmailDuplicatedStart, checkEmailDuplicated),
		yield takeLatest(addMembershipPlanStart, addMembershipPlan),
		yield takeLatest(addTokenToAccountStart, addTokenToAccount),
		yield takeLatest(removeTokenToAccountStart, removeTokenToAccount),
		yield takeLatest(removeSubscriptionStart, removeSubscription),
		yield takeLatest(cancelSubscriptionStart, cancelMembershipPlan),
		yield takeLatest(cancelImmediatelyStart, cancelImmediately),
		yield takeLatest(canDeleteTokenCheckStart, canDeleteTokenCheck),
		yield takeLatest(fetchTransientInfoStart, fetchTransientInfo),
		yield takeLatest(fetchPricingFeesStart, calculatePricing),
		yield takeLatest(saveSubscriptionStart, saveSubscription),
		yield takeLatest(resumeSubscriptionStart, resumeSubscription),
		yield takeLatest(paySubscriptionStart, paySubscription),
		yield takeLatest(fetchBillingHistoryStart, fetchBillingHistory),
		yield takeLatest(fetchOrderAccountStart, fetchOrderAccount),
		yield takeLatest(
			fetchReceiptHistoryContentHtmlStart,
			fetchReceiptHistoryConentHtml
		),
		yield takeLatest(sendReceiptEmailStart, sendReceiptEmail),
		yield takeLatest(
			fetchTransientOwnerAccountStart,
			fetchTransientAccountOwnerTokens
		),
	];
}
